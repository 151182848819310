import React from 'react'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { BannerCard } from './components/BannerCard'
/*tslint:disable:no-import-side-effect*/
import 'swiper/swiper-bundle.css'
/*tslint:disable:no-import-side-effect*/
import './styles.css'

export type BannerSlideType = {
  title: string
  subtitle: string
  imageUrl: string
  action: {
    title: string
    onClick: () => void
  }
  themeColor?: string
  themeBackgroundColor?: string
}

type BannerSliderProps = {
  slides: BannerSlideType[]
}

export const BannerSlider: React.FC<BannerSliderProps> = ({ slides }) => {
  return (
    <Swiper
      className="slider"
      modules={[Pagination, Autoplay, Navigation]}
      slidesPerView={1}
      grabCursor={true}
      initialSlide={1}
      speed={500}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }}
      spaceBetween={30}
      centeredSlides={true}
      pagination={{ dynamicBullets: true, clickable: true }}
      navigation={true}
    >
      {slides.map((slide, i) => {
        return (
          <SwiperSlide key={i} className="swiper-slide">
            <BannerCard key={i} {...slide} />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
